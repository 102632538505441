<script>
  import { orgOperators, project } from "$lib/services/store";
  import { translations } from "$lib/data/translations";
  import { supabase } from "$lib/services/db";
  import Icons from "$lib/main/components/Icons.svelte";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";

  let showSearch = false;
  let searchTerm = "";
  let searchResults = [];
  let searchResultsReviews = null;
  let searchResultsSlots = null;
  let searchResultsArticles = null;

  $: {
    searchResultsReviews = searchResults.filter((obj) => {
      return obj.parent_id === $project.data.categoryPages.reviews.id ?? "";
    });
    searchResultsSlots = searchResults.filter((obj) => {
      return obj.parent_id === $project.data.categoryPages.slots.id ?? "";
    });
    searchResultsArticles = searchResults.filter((obj) => {
      return (
        (obj.parent_id != $project.data.categoryPages.reviews.id ?? "") &&
        (obj.parent_id != $project.data.categoryPages.slots.id ?? "")
      );
    });
  }

  const disableBodyScroll = () => {
    if (typeof window !== "undefined") {
      document.body.classList.add("no-scroll");
    }
  };

  const enableBodyScroll = () => {
    if (typeof window !== "undefined") {
      document.body.classList.remove("no-scroll");
    }
  };

  $: if (showSearch) {
    disableBodyScroll();
  } else {
    enableBodyScroll();
  }

  const search = async () => {
    let { data } = await supabase
      .from("content")
      .select("slug, title, parent_id")
      .ilike("title", `%${searchTerm}%`)
      .eq("project_key", $project.key)
      .eq("is_published", true);
    searchResults = data;
  };

  const toggleSearchModal = () => {
    showSearch = !showSearch;

    if (showSearch) {
      // Timeout to make sure the modal is open before focusing the input, otherwise it won't work
      setTimeout(() => {
        document.getElementById("search-input").focus();
      }, 100);
    }
  };
</script>

<div class="backdrop" class:showSearch on:click={() => (showSearch = false)}></div>
<div class="search-container">
  <input
    type="text"
    placeholder={`${translations["Search"][$project.settings.lang]}`}
    name="search"
    on:focus={toggleSearchModal}
    id="search-input"
    bind:value={searchTerm}
    on:keyup={() => search()} />
  <button type="submit">
    <Icons name="search" height="24px" width="24px" strokeWidth="3" color="#fff" />
  </button>
</div>

{#if searchTerm && showSearch}
  <div class="search-result">
    <ul class="review">
      <h4><Translate text="Casinos" /></h4>
      {#each searchResultsReviews as result, i}
        {#if i < 10}
          {@const operator_lower = result.slug.split("/").slice(-1)}
          {@const operator = $orgOperators.find((x) => x.name_lower === operator_lower[0])}
          {@const color = `rgba(${operator?.visuals[$project.key]?.[$project.settings?.market]?.primary_color})`}
          <li>
            <a href={result.slug}>
              <img
                loading="lazy"
                width="100px"
                height="40px"
                style="background-color: {color}"
                src={$project.data?.imageFolders.operators + operator_lower + ".png?w=200&q=80&auto=format"}
                alt={result.title} />
              <span>{result.title}</span>
            </a>
          </li>
        {/if}
      {/each}
    </ul>
    <ul class="slots">
      <h4>Slots</h4>
      {#each searchResultsSlots as result, i}
        {#if i < 10}
          {@const casino_game_lower = result.slug.split("/").slice(-1)}
          <li>
            <a href={result.slug}>
              <img
                loading="lazy"
                width="60px"
                height="40px"
                src={$project.data?.imageFolders.casinoGames + casino_game_lower + ".jpg?w=120&h=80&q=80&auto=format"}
                alt={result.title} />
              <span>{result.title}</span>
            </a>
          </li>
        {/if}
      {/each}
    </ul>
    <ul class="articles">
      <h4><Translate text="Articles" /> & <Translate text="News" lowercase /></h4>
      {#each searchResultsArticles as result, i}
        {#if i < 20}
          <li><a href={result.slug}>{result.title}</a></li>
        {/if}
      {/each}
    </ul>
  </div>
{/if}

<style>
  .search-container {
    height: 45px;
    position: relative;
    z-index: 11;
    input[type="text"] {
      height: 100%;
      width: 250px;
      font-size: 17px;
      padding: 0 10px;
      border: none;
      border-radius: 4px 0 0 4px;
      font-size: 16px;
      font-weight: 700;
      color: #575757;
      &:focus {
        outline: none;
      }
    }
    button {
      float: right;
      height: 100%;
      width: 55px;
      background: #a02d28;
      border: 1.5px solid white;
      font-size: 17px;
      cursor: pointer;
      border-radius: 0 4px 4px 0;
      &:hover {
        background-color: #ccc;
      }
    }
  }

  .search-result {
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    display: grid;
    overflow: hidden;
    overflow-y: auto;
    grid-template:
      "review   "
      "slots    "
      "articles ";
    align-items: start;
    gap: 1rem;
    background-color: var(--bg-clr-alt);
    border-radius: 8px;
    width: 100%;
    max-height: 80vh;
    padding: 10px 25px;
    z-index: 11;

    @media only screen and (min-width: 576px) {
      max-width: 540px;
    }
    @media only screen and (min-width: 768px) {
      max-width: 720px;
    }
    @media only screen and (min-width: 992px) {
      max-width: 960px;
    }
    @media only screen and (min-width: 1200px) {
      max-width: 1140px;
    }

    ul {
      padding: 0 1rem;
      margin: 0;
      gap: 0.625rem;
      list-style: none;
      h4 {
        text-align: center;
      }
      li {
        a {
          display: flex;
          border-radius: var(--bd-rad);
          padding: 0.625rem;
          font-size: 0.875rem;
          color: #000;
          &:hover {
            background-color: #fff;
          }
        }
      }
      &.review,
      &.slots {
        grid-area: review;
        li {
          a {
            align-items: center;
            gap: 1rem;
            img {
              border-radius: var(--bd-rad-sm);
              display: block;
              padding: 0.5rem;
              object-fit: contain;
            }
          }
        }
      }
      &.slots {
        grid-area: slots;
        li {
          a {
            img {
              padding: 0;
            }
          }
        }
      }
      &.articles {
        grid-area: articles;
      }
    }
  }

  @container window (min-width: 576px) {
    .search-result {
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        h4 {
          grid-column: 1 / 3;
        }
      }
    }
  }

  @container window (min-width: 768px) {
    .search-result {
      grid-template:
        "review   slots   "
        "articles articles";
      grid-template-columns: 1fr 1fr;
      ul {
        grid-template-columns: 1fr;
        h4 {
          grid-column: unset;
        }
        &.articles {
          grid-template-columns: 1fr 1fr 1fr;
          h4 {
            grid-column: 1 / 4;
          }
        }
      }
    }
  }

  @container window (min-width: 992px) {
    .search-result {
      grid-template: "review  slots  articles";
      grid-template-columns: 1fr 1fr 1fr;
      ul {
        &.articles {
          grid-template-columns: 1fr;
          h4 {
            grid-column: unset;
          }
        }
      }
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 10;
    &.showSearch {
      opacity: 1;
      visibility: visible;
    }
  }
</style>
